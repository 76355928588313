import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HoverSoundButton from './OnHoverSound';

const chatRoomLinks = [
  { name: 'Sim_X01', url: '/chat/journey_01', photoUrl: '/images/folder.png' },
  { name: 'Sim_X02', url: '/chat/journey_01', photoUrl: '/images/folder.png' },
  { name: 'Sim_X03', url: '/chat/journey_01', photoUrl: '/images/folder.png' },
];

const SignUpPage = () => {
  const history = useNavigate();
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!userInteracted) {
        const silentAudio = new Audio();
        silentAudio.play().catch(() => {});
        setUserInteracted(true);
        document.removeEventListener('click', handleUserInteraction);
      }
    };
    document.addEventListener('click', handleUserInteraction);
    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, [userInteracted]);

  const handleCardClick = (url, photoUrl) => {
    history(url, { state: { photoUrl } });
  };

  return (
    <div className="mx-8 py-8 min-h-[calc(100vh-228px)] sm:min-h-[calc(100vh-160px)] lg:relative bg-gray-900 text-white">
      <h1 className="text-2xl font-bold mt-8 pb-4 text-left">Mission Selector</h1>
      <p className="text-justify pb-4">
        Click on a file to begin your temporal journey through Singapore's history.
      </p>
      <div className="relative pt-8">
        <div className="grid grid-cols-1 min-[320px]:grid-cols-3 sm:grid-cols-3 gap-2.5 md:gap-4">
          {chatRoomLinks.map((link, index) => (
            <HoverSoundButton
              className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer hover:bg-gray-700"
              onClick={() => handleCardClick(link.url, link.photoUrl)}
              key={index}
            >
              <div className="flex flex-col items-center">
                <img src={link.photoUrl} alt={link.name} className="w-20 h-20 transform transition-transform duration-300 hover:scale-110" />
                <span className="text-center mt-2">{link.name}</span>
              </div>
            </HoverSoundButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
