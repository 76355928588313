import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ChatPage from './ChatPage';

const validChatTypes = ['journey_01'];

function ChatWrapper({ isPlaying, handleAudioToggle }) {
  const { chatType } = useParams();

  if (!validChatTypes.includes(chatType)) {
    return <Navigate to="/" />; // Redirect to home page if chatType is invalid
  }

  return <ChatPage chatType={chatType} isPlaying={isPlaying} handleAudioToggle={handleAudioToggle} />;
}

export default ChatWrapper;
