export const generateCreatureBioAndImage = async (conversation, finalAssessment, chatType, userName, userEmail, startTime, setMessages, setGameEnded) => {
    try {
      const finalAssessmentMessage = { sender: 'ai', text: finalAssessment };
      setMessages((prevMessages) => [...prevMessages, finalAssessmentMessage]);
  
      const bioRes = await fetch('/.netlify/functions/SimpleCallOpenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: finalAssessment, creature: chatType }),
      });
  
      if (!bioRes.ok) {
        const errorText = await bioRes.text();
        console.error('Failed to generate creature bio:', errorText);
        throw new Error(errorText);
      }
  
      const bioData = await bioRes.json();
      let sumUp = null;
      const bioText = bioData.response.replace(/#([^#]+)#/g, (match, p1) => {
        sumUp = p1;
        return '';
      }).trim();
  
      const bioMessage = { sender: 'ai', text: bioText };
      setMessages((prevMessages) => [...prevMessages, bioMessage]);
  
      const totalTime = Math.floor((Date.now() - startTime) / 1000);
  
      const jobRes = await fetch('/.netlify/functions/generateJob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: bioText, chat: conversation, userName, userEmail, totalTime, finalAssessment, sumUp }),
      });
  
      if (!jobRes.ok) {
        const errorText = await jobRes.text();
        console.error('Failed to create job:', errorText);
        throw new Error(errorText);
      }
  
      const jobData = await jobRes.json();
      const jobId = jobData.jobId;
  
      // Trigger the background function for image generation
      const backgroundRes = await fetch('/.netlify/functions/generateImage-background', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobId, prompt: bioText, chat: conversation, userName, userEmail, totalTime, finalAssessment, sumUp }),
      });
  
      if (!backgroundRes.ok) {
        const errorText = await backgroundRes.text();
        console.error('Failed to trigger background function:', errorText);
        throw new Error(errorText);
      }
  
      const loadingMessage = { sender: 'ai', text: 'Generating image, please wait...' };
      setMessages((prevMessages) => [...prevMessages, loadingMessage]);
  
      const pollForImage = async (jobId) => {
        try {
          const statusRes = await fetch(`/.netlify/functions/jobStatus?jobId=${jobId}`);
          if (!statusRes.ok) {
            throw new Error('Failed to fetch job status');
          }
  
          const statusText = await statusRes.text();
          if (!statusText) {
            console.error('Empty response from job status, retrying...');
            setTimeout(() => pollForImage(jobId), 5000);
            return;
          }
  
          let statusData;
          try {
            statusData = JSON.parse(statusText);
          } catch (error) {
            console.error('Failed to parse job status response:', error);
            setTimeout(() => pollForImage(jobId), 5000);
            return;
          }
  
          if (statusData.status === 'complete') {
            const imageMessage = { sender: 'ai', text: `![Generated Image](${statusData.media})` };
            const galleryLink = `[Temporal Blueprint Discovered - View in Archives](https://sg.leylinexr.com/gallery/${statusData.entryId})`;
            setMessages((prevMessages) => [
              ...prevMessages,
              imageMessage,
              { sender: 'ai', text: galleryLink }
            ]);
  
            setGameEnded(true);
            console.log('Creature bio and image generated successfully:', statusData.media);
          } else if (statusData.status === 'failed') {
            throw new Error(statusData.error);
          } else {
            setTimeout(() => pollForImage(jobId), 5000);
          }
        } catch (error) {
          console.error('Error polling job status:', error);
          const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
          setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
      };
  
      pollForImage(jobId);
    } catch (error) {
      console.error('Error generating creature bio or image:', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };
  