import { generateCreatureBioAndImage } from './generateCreatureBioAndImage';

export const handleSubmit = async (inputPrompt, stage, setStage, setPrompt, setUserName, setUserEmail, validateEmail, setMessages, messages, setLoading, userName, userEmail, chatType, setGameEnded, startTime, setStartTime) => {
  inputPrompt = inputPrompt || ''; // Ensure inputPrompt is a string
  if ((typeof inputPrompt !== 'string' || inputPrompt.trim() === '') && stage !== 'ready') return;

  const newMessage = { sender: 'user', text: inputPrompt };
  setMessages((prevMessages) => [...prevMessages, newMessage]);
  setPrompt('');

  if (stage === 'name') {
    setUserName(inputPrompt);
    setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: `Identity Validated **${inputPrompt}**.\n\nPlease enter your email:` }]);
    setStage('email');
  } else if (stage === 'email') {
    if (validateEmail(inputPrompt)) {
      setUserEmail(inputPrompt);
      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: 'Click "Ready!" to start the mission.' }]);
      setStage('ready');
    } else {
      setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: 'Sorry. This is an invalid email format.\n\nPlease enter a valid email:' }]);
    }
  } else if (stage === 'ready' && inputPrompt === 'Ready!') {
    setMessages((prevMessages) => [...prevMessages, { sender: 'ai', text: 'Mission Started!' }]);
    setStage('mission');
    setLoading(true);
    setStartTime(Date.now());

    const formattedMessages = messages.slice(1).map((msg) => ({
      role: msg.sender === 'ai' ? 'assistant' : 'user',
      content: msg.text
    }));
    formattedMessages.push({ role: 'user', content: 'Ready!' });

    try {
      const res = await fetch('/.netlify/functions/callOpenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: formattedMessages, userName, userEmail, chatType }),
      });

      if (res.ok) {
        const result = await res.json();
        let aiMessageText = result.response;

        const fullHistory = [...formattedMessages, { role: 'assistant', content: aiMessageText }]
          .map((msg) => `${msg.role}: ${msg.content}`)
          .join('\n');

        if (aiMessageText.endsWith('&END&') || aiMessageText.endsWith('**&END&**')) {
          aiMessageText = aiMessageText.replace('&END&', '').replace('**&END&**', '');
          await generateCreatureBioAndImage(fullHistory, aiMessageText, chatType, userName, userEmail, startTime, setMessages, setGameEnded);
        } else {
          const aiMessage = { sender: 'ai', text: aiMessageText, isOpenAIResponse: true }; // Add flag here
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
        }
      } else {
        const errorText = await res.text();
        throw new Error(`Failed to fetch API: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
    setLoading(false);
  } else {
    setLoading(true);

    const formattedMessages = messages.slice(1).map((msg) => ({
      role: msg.sender === 'ai' ? 'assistant' : 'user',
      content: msg.text
    }));
    formattedMessages.push({ role: 'user', content: inputPrompt });

    try {
      const res = await fetch('/.netlify/functions/callOpenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: formattedMessages, userName, userEmail, chatType }),
      });

      if (res.ok) {
        const result = await res.json();
        let aiMessageText = result.response;

        const fullHistory = [...formattedMessages, { role: 'assistant', content: aiMessageText }]
          .map((msg) => `${msg.role}: ${msg.content}`)
          .join('\n');

        if (aiMessageText.endsWith('&END&') || aiMessageText.endsWith('**&END&**')) {
          aiMessageText = aiMessageText.replace('&END&', '').replace('**&END&**', '');
          await generateCreatureBioAndImage(fullHistory, aiMessageText, chatType, userName, userEmail, startTime, setMessages, setGameEnded);
        } else {
          const aiMessage = { sender: 'ai', text: aiMessageText, isOpenAIResponse: true }; // Add flag here
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
        }
      } else {
        const errorText = await res.text();
        throw new Error(`Failed to fetch API: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
    setLoading(false);
  }
};
