import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const numberClass = 'w-8 py-2 rounded-lg';

    const renderPaginationMobile = () => {
      const pages = [];

      if (totalPages <= 3) {
        // Show all pages if total pages are less than or equal to 3
        for (let i = 1; i <= totalPages; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => onPageChange(i)}
              className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {i}
            </button>
          );
        }
      } else {
        if (currentPage === 1) {
          // Show the first page and ellipsis + last page when on the first page
          pages.push(
            <button
              key={1}
              onClick={() => onPageChange(1)}
              className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              1
            </button>
          );
          pages.push(
            <button
              key={2}
              onClick={() => onPageChange(2)}
              className={`${numberClass} ${currentPage === 2 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              2
            </button>
          );
          pages.push(
            <button
              key={3}
              onClick={() => onPageChange(3)}
              className={`${numberClass} ${currentPage === 3 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              3
            </button>
          );
          pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
          pages.push(
            <button
              key={totalPages}
              onClick={() => onPageChange(totalPages)}
              className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {totalPages}
            </button>
          );
        } else if (currentPage === totalPages) {
          // Show the first page + ellipsis and the last page when on the last page
          pages.push(
            <button
              key={1}
              onClick={() => onPageChange(1)}
              className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              1
            </button>
          );
          pages.push(<span key="end-dots" className="px-2 py-2">...</span>);
          pages.push(
            <button
              key={totalPages}
              onClick={() => onPageChange(totalPages)}
              className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {totalPages}
            </button>
          );
        } else {
          if (currentPage > 1) {
            // Show the previous page
            pages.push(
              <button
                key={currentPage - 1}
                onClick={() => onPageChange(currentPage - 1)}
                className={`${numberClass} ${currentPage === currentPage - 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {currentPage - 1}
              </button>
            );
          }
  
          // Show the current page
          pages.push(
            <button
              key={currentPage}
              onClick={() => onPageChange(currentPage)}
              className={`${numberClass} ${currentPage ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {currentPage}
            </button>
          );
  
          if (currentPage < totalPages) {
            // Show the next page
            pages.push(
              <button
                key={currentPage + 1}
                onClick={() => onPageChange(currentPage + 1)}
                className={`${numberClass} ${currentPage === currentPage + 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {currentPage + 1}
              </button>
            );
          }
          if (currentPage !== totalPages - 1) {
            pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
          }
        }
      }
  
      return pages;
    };

    const renderPaginationDesktop = () => {
      const pages = [];

      if (totalPages <= 5) {
        // Show all pages if total pages are less than or equal to 5
        for (let i = 1; i <= totalPages; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => onPageChange(i)}
              className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {i}
            </button>
          );
        }
      } else {
        if (currentPage === 1) {
          // Show the first page, next 3 pages, ellipsis, and the last page when on the first page
          for (let i = 1; i <= 4; i++) {
            pages.push(
              <button
                key={i}
                onClick={() => onPageChange(i)}
                className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {i}
              </button>
            );
          }
          pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
          pages.push(
            <button
              key={totalPages}
              onClick={() => onPageChange(totalPages)}
              className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              {totalPages}
            </button>
          );
        } else if (currentPage === totalPages) {
          // Show the first page, ellipsis, and the last 4 pages when on the last page
          pages.push(
            <button
              key={1}
              onClick={() => onPageChange(1)}
              className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
            >
              1
            </button>
          );
          pages.push(<span key="end-dots" className="px-2 py-2">...</span>);
          for (let i = totalPages - 3; i <= totalPages; i++) {
            pages.push(
              <button
                key={i}
                onClick={() => onPageChange(i)}
                className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {i}
              </button>
            );
          }
        } else {
          // Show pages around the current page
          const startPage = Math.max(1, currentPage - 2);
          const endPage = Math.min(totalPages, currentPage + 2);

          if (startPage > 1) {
            pages.push(
              <button
                key={1}
                onClick={() => onPageChange(1)}
                className={`${numberClass} ${currentPage === 1 ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                1
              </button>
            );
            if (startPage > 2) {
              pages.push(<span key="start-dots" className="px-2 py-2">...</span>);
            }
          }

          for (let i = startPage; i <= endPage; i++) {
            pages.push(
              <button
                key={i}
                onClick={() => onPageChange(i)}
                className={`${numberClass} ${currentPage === i ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {i}
              </button>
            );
          }

          if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
              pages.push(<span key="end-dots" className="px-2 py-2">...</span>);
            }
            pages.push(
              <button
                key={totalPages}
                onClick={() => onPageChange(totalPages)}
                className={`${numberClass} ${currentPage === totalPages ? 'bg-cyan-500 text-white' : 'bg-white text-black'}`}
              >
                {totalPages}
              </button>
            );
          }
        }
      }
    return pages;
  };

  return (
    <div className="flex justify-center mt-4 gap-1 relative">
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="px-2 py-2 border bg-white text-cyan-500 rounded-lg absolute left-0"
        >
            <ArrowLeftIcon className='h-5 w-5'/>
        </button>
      )}
      <div className="flex sm:hidden gap-1">
        {renderPaginationMobile()}
      </div>
      <div className="hidden sm:flex gap-1">
        {renderPaginationDesktop()}
      </div>
      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="px-2 py-2 border bg-white text-cyan-500 rounded-lg absolute right-0"
        >
          <ArrowRightIcon className='h-5 w-5'/>
        </button>
      )}
    </div>
  );
};

export default Pagination;
