import React from 'react';

class HoverSoundButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canPlaySound: true,
    };
  }

  playSound = () => {
    if (this.state.canPlaySound) {
      const audio = new Audio('/audio/button.wav'); // replace with your sound file
      audio.volume = 0.2; // set the volume to 0.2
      audio.play().catch(() => {});
      this.setState({ canPlaySound: false });
      setTimeout(() => this.setState({ canPlaySound: true }), 500);
    }
  }

  render() {
    return (
      <button onMouseEnter={this.playSound} {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

export default HoverSoundButton;
