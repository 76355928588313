import React from 'react';

const Input = ({ prompt, handleInputChange, handleSubmit, loading, stage }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(prompt);
    }
  };

  return (
    <div className="sticky bottom-0 bg-gray-900 py-4 border-t border-gray-700">
      {stage === 'ready' ? (
        <button
          onClick={() => handleSubmit('Ready!')}
          disabled={loading}
          className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Ready!
        </button>
      ) : (
        <div className="flex items-center mb-2">
          <input
            type="text"
            value={prompt}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter your input"
            className="flex-grow p-2 border border-gray-700 bg-gray-800 text-white rounded-l mr-2"
          />
          <button
            onClick={() => handleSubmit(prompt)}
            disabled={loading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r disabled:bg-gray-700 flex items-center justify-center h-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default Input;
