import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ photoUrl, chatType, handleAudioToggle, isPlaying }) => {
  // Define the mapping
  const chatTypeMapping = {
    journey_01: 'X_01',
    // Add more mappings if needed
  };

  // Replace chatType if it exists in the mapping
  const displayChatType = chatTypeMapping[chatType] || chatType;

  return (
    <div className="flex justify-between items-center py-4 sticky top-0 bg-gray-900 border-b border-gray-700">
      <div className='flex justify-between items-center'>
        <img src={photoUrl} alt={chatType} className="w-6 h-6" />
        <h1 className="text-base sm:text-xs md:text-base font-semibold capitalize ml-1">{displayChatType}</h1>
      </div>
      <div className="flex items-center">
        <button
          onClick={handleAudioToggle}
          className="bg-gray-800 text-white border border-white font-bold py-2 px-3 md:px-2 rounded lg:hover:bg-gray-700 transition-colors shadow-lg mr-4"
        >
          {isPlaying ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
            </svg>
          )}
        </button>
        <Link to="/gallery" className="text-white rounded lg:hover:bg-gray-700 font-bold py-2 px-4 border border-white">
          Archives
        </Link>
      </div>
    </div>
  );
};

export default Header;
