import React from 'react';
import ReactMarkdown from 'react-markdown';

const Message = ({ message, handlePlayAudio }) => (
  <div className={`message ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
    <div
      className={`inline-block p-2 rounded-lg mb-2 max-w-full overflow-auto ${
        message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'
      }`}
    >
      <div className="flex flex-col">
        <div className="flex-1">
          <ReactMarkdown>{message.text}</ReactMarkdown>
        </div>
        {message.sender === 'ai' && message.isOpenAIResponse && (
          <div className="flex justify-end mt-2">
            <button
              onClick={() => handlePlayAudio(message.text)}
              className="bg-black opacity-25 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Message;
