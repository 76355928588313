import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import ChatWindow from './ChatWindow';
import Input from './Input';
import { handleSubmit } from './utils/handleSubmit'; // Import the function

const bio = {
  journey_01: `Temporal comms log:

- **Starting year**: 1941
- **Main Mission**: Restore original timeline
- **Description**: You are conversing with world war two hero Lim Bo Seng over radio, and have a chance to save his life, changing the course of history.`
};

function ChatPage({ chatType, isPlaying, handleAudioToggle }) {
  const location = useLocation();
  const { photoUrl } = location.state || {};
  const welcomeMessage = `Greetings, your temporal communicator is online.`;
  const bioMessage = bio[chatType] ? bio[chatType] : '';
  const enterNameMessage = `Agent is ready to access temporal device to engage in **${chatType}**.\n\nBefore proceeding, please **enter your name:**`;

  const initialMessages = [
    { sender: 'ai', text: welcomeMessage },
    { sender: 'ai', text: bioMessage },
    { sender: 'ai', text: enterNameMessage }
  ];

  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState(initialMessages);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState('name');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [gameEnded, setGameEnded] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);

  useEffect(() => {
    setMessages([
      { sender: 'ai', text: welcomeMessage },
      { sender: 'ai', text: bioMessage },
      { sender: 'ai', text: enterNameMessage }
    ]);
  }, [welcomeMessage, bioMessage, enterNameMessage]);

  const messagesEndRef = useRef(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const onSubmit = (inputPrompt) => {
    handleSubmit(
      inputPrompt,
      stage,
      setStage,
      setPrompt,
      setUserName,
      setUserEmail,
      validateEmail,
      setMessages,
      messages,
      setLoading,
      userName,
      userEmail,
      chatType,
      setGameEnded,
      startTime,
      setStartTime
    );
  };

  const handlePlayAgain = () => {
    setMessages([{ sender: 'ai', text: 'Click "Ready!" to start the game.' }]); // Update message
    setStage('ready');
    setGameEnded(false);
    setStartTime(null); // Reset the start time
  };

  const handlePlayAudio = async (messageText) => {
    if (currentAudio) {
      // Do not play new audio if one is already playing
      return;
    }
  
    const startKeyword = "Message:";
    const endKeyword = "Respond:";
  
    // Extract the text between "Message:" and "Respond:"
    const startIndex = messageText.indexOf(startKeyword) + startKeyword.length;
    const endIndex = messageText.indexOf(endKeyword);
  
    if (startIndex < startKeyword.length || endIndex === -1 || startIndex >= endIndex) {
      console.error('Failed to extract audio message: Invalid format');
      return;
    }
  
    const audioMessage = messageText.substring(startIndex, endIndex).trim();
  
    try {
      const res = await fetch('/.netlify/functions/getAudio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: audioMessage }),
      });
  
      if (res.ok) {
        const audioData = await res.json();
        const audio = new Audio(audioData.audioUrl);
        setCurrentAudio(audio);
        audio.play();
        audio.onended = () => {
          setCurrentAudio(null);
        };
      } else {
        const errorText = await res.text();
        throw new Error(`Failed to fetch audio: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching audio: ', error);
      const errorMessage = { sender: 'ai', text: 'Error: ' + error.message };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };
  

  return (
    <div className="min-h-screen flex flex-col sticky top-0 bottom-0 left-0 bg-gray-900 w-full text-white">
      <div className="w-full mx-auto px-4 border border-gray-700 shadow-lg rounded-lg flex flex-col flex-grow">
        <Header photoUrl={photoUrl} chatType={chatType} handleAudioToggle={handleAudioToggle} isPlaying={isPlaying} />
        <ChatWindow messages={messages} handlePlayAudio={handlePlayAudio} loading={loading} messagesEndRef={messagesEndRef} />
        {!gameEnded && (
          <Input prompt={prompt} handleInputChange={handleInputChange} handleSubmit={onSubmit} loading={loading} stage={stage} />
        )}
        {gameEnded && (
          <div className="sticky bottom-0 bg-gray-900 py-4 border-t border-gray-700">
            <button
              onClick={handlePlayAgain}
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Play Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatPage;
