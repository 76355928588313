import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon, AdjustmentsHorizontalIcon, AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';
import Image from './ImageComponent';
import DropDown from './DropDown';
import Pagination from './Pagination';
import HoverSoundCard from './OnHoverSound'; // Import the HoverSoundCard component

export default function GalleryPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [isAZ, setIsAZ] = useState(true);
  const [isNewestFirst, setIsNewestFirst] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Filter and get current items pagination
  const filteredData = data.filter(item => 
    item.user.toLowerCase().includes(searchTerm.toLowerCase()) || 
    item.bio?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/.netlify/functions/getAnimadex');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log(result); // Log the data to verify `createdAt` field
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sortAlphabetically = () => {
    const sorted = [...data].sort((a, b) => 
      isAZ ? a.user.localeCompare(b.user) : b.user.localeCompare(a.user)
    );
    setIsAZ(!isAZ);
    setData(sorted);
  };

  const sortByDate = () => {
    const sorted = [...data].sort((a, b) => 
      isNewestFirst ? new Date(b.createdAt) - new Date(a.createdAt) : new Date(a.createdAt) - new Date(b.createdAt)
    );
    setIsNewestFirst(!isNewestFirst);
    setData(sorted);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [toggleIcon, setToggleIcon] = useState(true);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setToggleIcon(!toggleIcon);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="max-w-7xl mt-6 pb-6 mx-6 min-[480px]:px-[12vw] min-h-[calc(100vh-160px)] sm:px-0 lg:relative xl:mx-auto bg-gray-900 text-white">
      <h1 className="text-lg font-bold pb-4 text-left">
        ARCHIVES
      </h1>
      <p className='text-justify pb-4'>
        This is an archive of temporal journey entries. <br></br>Click on an entry to view more details.
      </p>
      <div className='flex relative lg:absolute lg:top-0 lg:right-4'>
        <div className="flex flex-1 items-center justify-center pr-2 lg:justify-end">
          <div className="w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full rounded-md border-0 bg-gray-700 text-white py-1.5 pl-10 pr-3 ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search"
                type="search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <button onClick={toggleDropdown} className='content-center p-2 bg-black rounded'>
          {toggleIcon &&
            <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-500 fill-white" aria-hidden="true" />
          }
          {showDropdown &&
            <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-500 fill-white" aria-hidden="true" />
          }
        </button>
        {showDropdown && 
          <DropDown className='bg-slate-100 absolute top-9 right-0 left-0'
          sortAlphabetically={sortAlphabetically}
          sortByDate={sortByDate} />
        }
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">Error: {error}</p>}
      {data && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-3 gap-4 pt-8">
            {currentItems.map((item) => (
              <HoverSoundCard 
                key={item.id} 
                soundSrc="/path/to/hover-sound.mp3"
                className="no-underline text-white lg:hover:-translate-y-2 transition-transform ease-in-out"
              >
                <Link to={`/gallery/${item.id}`} className="no-underline text-white">
                  <div className="bg-gray-800 pt-4 rounded-lg shadow-md cursor-pointer">
                    <div className="flex justify-center">
                      {item.media && (
                        <Image
                          src={item.media}
                          alt={item.user}
                          className="h-64 w-64 object-cover" 
                        />
                      )}
                    </div>
                    <span className='block p-4 text-gray-200'>
                      <strong>User:</strong> {item.user}
                    </span>
                  </div>
                </Link>
              </HoverSoundCard>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
}
