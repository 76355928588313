import React from 'react';
import Message from './Message';

const ChatWindow = ({ messages, handlePlayAudio, loading, messagesEndRef }) => (
  <div className="chat-window flex flex-grow flex-col overflow-auto bg-gray-800 p-4 rounded-lg mb-4 max-h-full">
    {messages.map((message, index) => (
      <Message key={index} message={message} handlePlayAudio={handlePlayAudio} />
    ))}
    {loading && (
      <div className="text-center text-gray-500">Loading...</div>
    )}
    <div ref={messagesEndRef} className="h-2 w-full" />
  </div>
);

export default ChatWindow;
