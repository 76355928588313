import React, { useState } from 'react';

export default function DropDown({ className, sortAlphabetically, sortByDate }) {
    const [dateOrder, setDateOrder] = useState('Newest-Oldest');
    const [alphaOrder, setAlphaOrder] = useState('Z-A');

    const handleDateClick = () => {
        sortByDate();
        setDateOrder(dateOrder === 'Newest-Oldest' ? 'Oldest-Newest' : 'Newest-Oldest');
        setAlphaOrder('Z-A');  // Reset alpha order to default
    };

    const handleAlphaClick = () => {
        sortAlphabetically();
        setAlphaOrder(alphaOrder === 'A-Z' ? 'Z-A' : 'A-Z');
        setDateOrder('Newest-Oldest');  // Reset date order to default
    };

    return (
        <ul className={`list-none p-0 m-0 text-left rounded-b-lg text-gray-900 font-bold ${className}`}>
            <li className="block">
                <button className='text-left capitalize p-4 w-full' onClick={handleDateClick}>
                    {dateOrder}
                </button>
            </li>
            <li className="block">
                <button className='text-left capitalize p-4 w-full' onClick={handleAlphaClick}>
                    {alphaOrder}
                </button>
            </li>
        </ul>
    );
}
