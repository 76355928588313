const navigation = [
    {
      name: 'Homepage',
      href: '/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M12 3L2 12h3v8h6v-5h2v5h6v-8h3L12 3z" />
        </svg>
      ),
    },
  ];
  
  export default function Footer() {
    return (
      <footer className="bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center pb-6 md:pt-0 md:order-1">
          </div>
          <div className="flex justify-center space-x-6 md:order-2">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6 fill-white" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-400">
              &copy; 2024 Purple Tree x LeylineXR. <br></br>All rights reserved. <a href="/dmca" className="text-gray-500 hover:text-gray-300">DMCA</a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
  