import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SignUp from './SignUpPage';
import GalleryPage from './GalleryPage';
import DetailPage from './DetailPage';
import ChatWrapper from './ChatWrapper';
import Footer from './Footer';
import DMCAPage from './DMCAPage';
import NavBar from './NavBar';
import { useEffect, useRef, useState } from 'react';

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.2; // Set volume to halfway
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, []);

  return (
    <div className="App bg-gray-900 text-white">
      <Router>
        <Content
          audioRef={audioRef}
          isPlaying={isPlaying}
          handleAudioToggle={handleAudioToggle}
        />
      </Router>
    </div>
  );
}

function Content({ audioRef, isPlaying, handleAudioToggle }) {
  const location = useLocation();
  const isChatPage = location.pathname.includes('chat');
  const isSignUpPage = location.pathname === '/';
  const isGalleryPage = location.pathname === '/gallery';

  return (
    <div className="relative min-h-screen w-full">
      <audio ref={audioRef} src="/audio/ambient2.mp3" loop />
      <div className="relative z-10">
        {(isSignUpPage || isGalleryPage) && (
          <NavBar
            isGalleryPage={isGalleryPage}
            isPlaying={isPlaying}
            handleAudioToggle={handleAudioToggle}
          />
        )}
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/chat/:chatType" element={<ChatWrapper isPlaying={isPlaying} handleAudioToggle={handleAudioToggle} />} />
          <Route path="/gallery/:detailId" element={<DetailPage />} />
          <Route path="/dmca" element={<DMCAPage />} />
        </Routes>
        {!isChatPage && <Footer />}
      </div>
    </div>
  );
}

export default App;
