import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = ({ isGalleryPage, isPlaying, handleAudioToggle }) => {
  return (
    <nav className="bg-gray-100 bg-opacity-10 p-4 shadow-md flex justify-between items-center">
      <div className="flex items-center">
        <img src='/images/sing.png' alt="Logo" className="h-8 w-8 mr-2 mb-2" />
        <h1 className="text-[0.7rem] md:text-lg font-bold text-white">Singaplay</h1>
      </div>
      <div className="flex items-center">
        <button
          onClick={handleAudioToggle}
          className="bg-gray-800 text-white border border-white font-bold py-2 px-3 md:px-2 rounded lg:hover:bg-gray-700 transition-colors shadow-lg mr-4"
        >
          {isPlaying ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
            </svg>
          )}
        </button>
        <Link
          to={isGalleryPage ? "/" : "/gallery"}
          className="bg-gray-800 text-white border border-white font-bold py-2 px-3 md:px-4 rounded lg:hover:bg-gray-700 transition-colors shadow-lg"
        >
          {isGalleryPage ? "Missions" : "Archives"}
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;
